export class FetchError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "FetchError";
  }
}

export class HttpError extends Error {
  #status: number;
  #requestId: string | undefined;
  #traceId: string | undefined;

  constructor(response: Response) {
    super(response.statusText);
    this.name = "HttpError";
    this.#status = response.status;
    this.#requestId = response.headers.get("x-yarequestid") ?? undefined;
    this.#traceId = response.headers.get("x-yatraceid") ?? undefined;
  }

  get status() {
    return this.#status;
  }

  get requestId() {
    return this.#requestId;
  }

  get traceId() {
    return this.#traceId;
  }
}
