/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Button } from "@superweb/ui";

export const SubmitBottomSheet = ({
  isLoading,
  text,
}: {
  isLoading: boolean;
  text: string;
}) => (
  <>
    <div
      css={{
        height: "48px",
      }}
    />
    <div
      css={{
        position: "absolute",
        bottom: "18px",
        width: "calc(100% - 32px)",
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      <Button
        view="action"
        type="submit"
        shape="squircle"
        progress={isLoading}
        text={text}
      />
    </div>
  </>
);
