/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useState, useRef, type ReactNode } from "react";
import { mergeProps, useDatePicker, useFocusRing } from "react-aria";
import { useDatePickerState, type DatePickerStateOptions } from "react-stately";
import type { Temporal } from "@js-temporal/polyfill";
import type { DateValue } from "@internationalized/date";

import { Field } from "../fields/field";
import { FieldLabelV2 } from "../fields/label";
import { ClearButton } from "../fields/buttons";
import { FieldDescription } from "../fields/description";
import { FieldErrorMessage } from "../fields/error-message";
import { useDebouncedState } from "../state";
import { useIsMobile } from "../mobile-context";

import { DateInput } from "./date-input";
import { Calendar, CalendarButton, CalendarDialog } from "./calendar";
import {
  calendarDateToPlainDate,
  plainDateToCalendarDate,
} from "./date-transform";

export type DateFieldState = {
  /**
   * Current input's value.
   */
  value?: Temporal.PlainDate;

  /**
   * Is set to `true` on change if the field contains partial or invalid date.
   */
  invalid?: boolean;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

export const DateField = ({
  label,
  description,
  state,
  min,
  max,
  icon,
  disabled = false,
  onChange,
  exclude,
  tooltip,
}: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: DateFieldState;

  /**
   * The minimum allowed date that a user may select in popover calendar.
   */
  min?: Temporal.PlainDate;

  /**
   * The maximum allowed date that a user may select in popover calendar.
   */
  max?: Temporal.PlainDate;

  /**
   * Icon at the start of the field.
   */
  icon?: ReactNode;

  /**
   * If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   */
  onChange: (state: DateFieldState) => void;

  /**
   * Excluded dates can't be selected in calendar.
   *
   * @returns true if date should be excluded
   */
  exclude?: (date: DateValue) => boolean;

  /**
   * Tooltip for each date cell.
   *
   * @returns tooltip content as a string or `undefined` if tooltip is not needed.
   */
  tooltip?: (state: {
    /**
     * Current date cell data
     */
    value: Temporal.PlainDate;
  }) => string | undefined;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const firstSegmentRef = useRef<HTMLDivElement>(null);

  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const ariaProps: DatePickerStateOptions<DateValue> = {
    label,
    description,
    errorMessage: state.errorMessage,

    // Туреscript does not allow set null in value field, but in runtime null is valid value if date partial
    // https://github.com/adobe/react-spectrum/issues/1890
    // https://github.com/adobe/react-spectrum/issues/3187
    value: (state.value
      ? plainDateToCalendarDate(state.value)
      : null) as DateValue,

    validationState:
      state.errorVisible && state.errorMessage ? "invalid" : "valid",

    maxValue: max ? plainDateToCalendarDate(max) : undefined,
    minValue: min ? plainDateToCalendarDate(min) : undefined,

    isDisabled: disabled,

    onChange: (value: DateValue | null) => {
      onChange({
        ...state,
        value: value ? calendarDateToPlainDate(value) : undefined,
        invalid: false,
        errorVisible: false,
        errorMessage: undefined,
      });
    },
    onBlur: () => {
      onChange({
        ...state,
        errorVisible: true,
        errorMessage: undefined,
      });
    },
  };

  const ariaState = useDatePickerState(ariaProps);

  const {
    groupProps,
    labelProps,
    descriptionProps,
    errorMessageProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(ariaProps, ariaState, ref);

  const isMobile = useIsMobile();

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Сorrects the triggering of clicks on elements under the overlay
  // by debouncing the open state
  const debouncedIsOpen = useDebouncedState(ariaState.isOpen, 0);
  // apply debounced state only on mobile in `true` -> `false` phase
  const isOpen = (isMobile && debouncedIsOpen) || ariaState.isOpen;

  const isShrunk = isFocused || isOpen || Boolean(state.value);

  const onInputChange = (hasSegmentsWithValue: boolean) => {
    onChange({
      ...state,
      value: state.value,
      invalid: !state.value && hasSegmentsWithValue,
    });
  };

  const onClearButtonPress = () => {
    firstSegmentRef.current?.focus();
    onChange({
      ...state,
      value: undefined,
      invalid: false,
      errorVisible: false,
    });
  };

  return (
    <>
      <Field
        fieldProps={mergeProps(groupProps, focusProps)}
        fieldRef={ref}
        focused={isFocused || isOpen}
        shrunk={isShrunk}
        disabled={disabled}
        icon={icon}
        label={
          <FieldLabelV2 shrunk={isShrunk} labelProps={labelProps}>
            {label}
          </FieldLabelV2>
        }
        input={
          <DateInput
            ariaPickerProps={fieldProps}
            firstSegmentRef={firstSegmentRef}
            onChange={onInputChange}
          />
        }
        clearButton={
          <ClearButton
            visible={Boolean(state.value) && (isFocused || isOpen)}
            onPress={onClearButtonPress}
          />
        }
        toolbar={<CalendarButton isDisabled={disabled} {...buttonProps} />}
        descriptionAndError={
          state.errorVisible && state.errorMessage ? (
            <FieldErrorMessage errorMessageProps={errorMessageProps}>
              {state.errorMessage}
            </FieldErrorMessage>
          ) : (
            description && (
              <FieldDescription descriptionProps={descriptionProps}>
                {description}
              </FieldDescription>
            )
          )
        }
        onClick={() => {
          firstSegmentRef.current?.focus();
        }}
      />

      {isOpen && (
        <CalendarDialog
          state={ariaState}
          triggerRef={ref}
          ariaDialogProps={dialogProps}
        >
          <Calendar {...calendarProps} exclude={exclude} tooltip={tooltip} />
        </CalendarDialog>
      )}
    </>
  );
};

export const createDateFieldState = (
  defaultValue?: DateFieldState,
): DateFieldState => {
  return {
    value: undefined,
    invalid: false,
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useDateFieldState = (defaultValue?: DateFieldState) => {
  return useState<DateFieldState>(createDateFieldState(defaultValue));
};
