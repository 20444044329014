/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Suspense, useEffect } from "react";
import {
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { QueryClient, QueryClientProvider } from "@superweb/api";
import { CssOptions } from "@superweb/css";
import {
  AppContainer,
  useUiColors,
  Spin,
  Theme,
  usePreviousState,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { useV1ParkInfoGet } from "@fleet/api/fleet-forms";
import { YangoTheme } from "@fleet/ui";

import { Form } from "./form";
import { Flags } from "./flags";
import { Messages } from "./intl";
import { AppLocale } from "./app-locale";
import { stringSearchParamParser, useSearchParams } from "./router";
import { Errors } from "./errors";
import { NotFound } from "./ui";
import { useLogHit } from "./analytics/logActions";
import { initGtag } from "./analytics/gtag";
import { initFBQ } from "./analytics/fbq";
import { initTTQ } from "./analytics/ttq";
import "./analytics/initYM";
import "./analytics/initFB";
import "./analytics/initTikTok";

const queryClient = new QueryClient();

const useLogLocation = (logHit: (url: string) => void) => {
  const location = useLocation();
  const prevPathname = usePreviousState(location.pathname);

  useEffect(() => {
    if (location.pathname !== prevPathname) {
      logHit(`${location.pathname}${location.search}`);
    }
  }, [location, prevPathname, logHit]);
};

const Root = () => {
  return (
    <AppContainer>
      <Flags>
        <Suspense
          fallback={
            <div
              css={{
                display: "grid",
                alignContent: "center",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <Spin />
            </div>
          }
        >
          <Messages>
            <Errors>
              <AppContent />
            </Errors>
          </Messages>
        </Suspense>
      </Flags>
    </AppContainer>
  );
};

const router = createBrowserRouter([{ path: "*", Component: Root }]);

export const App = () => {
  return (
    <CssOptions
      value={{
        nonce: (
          document.querySelector(
            "meta[property=csp-nonce]",
          ) as HTMLMetaElement | null
        )?.nonce,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AppLocale>
          <HelmetProvider>
            <Theme value={YangoTheme}>
              <RouterProvider router={router} />
            </Theme>
          </HelmetProvider>
        </AppLocale>
      </QueryClientProvider>
    </CssOptions>
  );
};

const AppContent = () => {
  const uiColors = useUiColors();
  const locale = useLocale();
  const logHit = useLogHit();

  useLogLocation(logHit);

  const [params] = useSearchParams({
    ref_id: stringSearchParamParser(),
    iframe: stringSearchParamParser(),
  });

  const isIframe = params.iframe === "true";

  const { data: parkInfo } = useV1ParkInfoGet(
    {
      headers: {
        language: locale.toString(),
      },
      query: { ref_id: params.ref_id },
    },
    {
      enabled: Boolean(params.ref_id),
      suspense: true,
    },
  );

  useEffect(() => {
    if (parkInfo?.ok) {
      switch (parkInfo.data.channel_id) {
        case "google_ads":
          const ga_id = parkInfo.data.google_adwords_id;
          initGtag(ga_id);
          break;
        case "facebook":
          const pixel_id = parkInfo.data.fb_pixel_id;
          initFBQ(pixel_id);
          break;
        case "tik_tok":
          const tiktok_pixel_id = parkInfo.data.tiktok_pixel_id;
          initTTQ(tiktok_pixel_id);
          break;
      }
    }
  }, [parkInfo]);

  if (!params.ref_id || !parkInfo?.ok) return <NotFound />;

  return (
    <div
      css={{
        display: "grid",
        minWidth: "min-content",
        minHeight: "100vh",
        gridTemplateRows: "minmax(min-content, 100vh) 1fr",
        isolation: "isolate",
        ...(isIframe && {
          maxHeight: "600px",
          maxWidth: "600px",
        }),
      }}
    >
      <div
        css={{
          display: "grid",
          minHeight: "100vh",
          backgroundColor: uiColors.background,
        }}
      >
        <Suspense
          fallback={
            <div
              css={{
                display: "grid",
                minWidth: "min-content",
                minHeight: "100vh",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Spin />
            </div>
          }
        >
          <Routes>
            <Route
              path="/forms"
              element={
                <Form
                  parkInfo={parkInfo.data}
                  refId={params.ref_id}
                  isIframe={isIframe}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};
