import { type ReactNode, lazy } from "react";
import type { PickingInfo } from "@deck.gl/core/typed";

const YandexMap = lazy(() => import("./yandex"));
const GoogleMap = lazy(() => import("./google"));

import type { Viewport } from "./types";
import type { Customization } from "./yandex-customization";

export type YandexProviderOptions = {
  mode?: "vector" | "raster" | "auto";
  theme?: "light" | "dark";
  customization?: Customization;
  /**
   * Allows you to disable Yandex Maps copyright, need to request permission for your api key
   */
  copyrights?: boolean;
  /**
   * Switches the underlay data source to OpenStreetMap, need to request permission for your api key
   */
  dataProvider?: "osm";
  apiKey: string;
  zoomStrategy?: "zoomToCenter" | "zoomToPointer";
};

type YandexParams = {
  provider: "yandex";
  providerOptions: YandexProviderOptions;
};

export type GoogleProviderOptions = {
  apiKey: string;
  mapId?: string;
};

type GoogleParams = {
  provider: "google";
  providerOptions: GoogleProviderOptions;
};

export const Map = ({
  provider,
  providerOptions,
  interactive = true,
  viewportControl = true,
  scrollZoom = true,
  doubleClickZoom = true,
  ...restProps
}: {
  /**
   * Map viewport parameters. Defines visible portion of a map. Example: \{ lat: 55.7524, lon: 37.610778, zoom: 10 \}
   */
  viewport: Viewport;
  /**
   * A callback that is triggered when map viewport changes. Returns parameters of a new viewport.
   */
  onViewportChange?: (viewport: Viewport) => void;
  /**
   * two-character language designation according to ISO 639-1. Example: en
   */
  language?: string;
  /**
   * two-character country and territory codes according to ISO 3166-1. Example: US
   */
  region?: string;
  /**
   * A callback that is triggered when map size changes. Returns dimensions of a viewport in pixels
   */
  onResize?: (size: { width: number; height: number }) => void;
  /**
   * Called when clicking on the map.
   */
  onClick?: (info: PickingInfo) => void;
  /**
   * A flag which defines if a map is responsive to user interaction (zoom, drag, click, etc).
   * @defaultValue true
   */
  interactive?: boolean;
  /**
   * A flag which defines if a map is responsive to user interaction with viewport (zoom and drag).
   * @defaultValue true
   */
  viewportControl?: boolean;
  /**
   * A flag which defines if a map is responsive to user interaction with viewport (zoom).
   * @defaultValue true
   */
  scrollZoom?: boolean;
  /**
   * Listen for a double click event from a user and zoom in on the map.
   * @defaultValue true
   */
  doubleClickZoom?: boolean;
  /**
   * Minimum and maximum map zoom value
   */
  zoomRange?: {
    min: number;
    max: number;
  };
  children?: ReactNode;
} & (YandexParams | GoogleParams)) => {
  switch (provider) {
    case "yandex":
      return (
        <YandexMap
          {...restProps}
          {...providerOptions}
          interactive={interactive}
          viewportControl={viewportControl}
          scrollZoom={scrollZoom}
          doubleClickZoom={doubleClickZoom}
          apiKey={providerOptions.apiKey}
        />
      );
    case "google":
      return (
        <GoogleMap
          {...restProps}
          {...providerOptions}
          interactive={interactive}
          viewportControl={viewportControl}
          scrollZoom={scrollZoom}
          doubleClickZoom={doubleClickZoom}
        />
      );
  }
};
