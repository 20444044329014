import { FetchError, HttpError } from "./errors";

let clientVersion = "";

/**
 * Set experimental header X-Client-Version
 * https://st.yandex-team.ru/FLEET-6121
 * delete before 15.6.2024
 */
export const experimental_setClientVersion = (
  appName: string,
  version?: string,
) => {
  clientVersion = `${appName}/${version ?? ""}`;
};

/**
 * Sends request and handles the response using provided handlers.
 *
 * Handlers is an object keyed by response status.
 *
 * Return value is a union type of return values of all handlers.
 *
 * If a successful response is received
 * and no matching handler is specified, a `TypeError` is thrown.
 *
 * If an unsuccessful response is received
 * and no matching handler is specified, an `HttpError` is thrown.
 *
 * If access_token cookie is present,
 * sends it in the Authorization header with the Bearer auth-scheme.
 *
 * @example
 * ```ts
 * await apiFetch(new Request(url), {
 *   200: async (r) => ({
 *     ok: true as const,
 *     data: await r.json() as Response200,
 *   }),
 *   400: async (r) => ({
 *     ok: false as const,
 *     error: await r.json() as Response400,
 *   }),
 * });
 * ```
 */
export const apiFetch = async <H extends Handlers>(
  request: Request,
  handlers: H,
): Promise<Result<H>> => {
  const accessToken = document.cookie
    .split("; ")
    .find((s) => s.startsWith("access_token="))
    ?.split("=")[1];
  if (accessToken) {
    request.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  /** TODO:
   * do not use apiFetch to requests other than authproxy
   * remove check pathname
   * https://st.yandex-team.ru/SUPERWEB-748
   * */
  const pathname = new URL(request.url, window.location.origin).pathname;
  if (clientVersion && pathname.startsWith("/api/")) {
    request.headers.set("X-Client-Version", clientVersion);
  }

  const response = await fetch(request).catch((error: Error) => {
    throw new FetchError(error.message);
  });

  const handler = handlers[response.status];
  if (handler) {
    return handler(response) as Promise<Result<H>>;
  }

  if (!response.ok) {
    throw new HttpError(response);
  }

  throw TypeError(`Handler for status ${response.status} is not specified`);
};

type Handler = (_: Response) => Promise<unknown>;

type Handlers = {
  [K in number]?: Handler;
} & {
  // Stop-gap to prevent old apiFetch usage from slipping in
  any?: never;
  clientError?: never;
  error?: never;
  ok?: never;
  serverError?: never;
};

type Result<H> = H extends {
  [K in number]?: (_: Response) => Promise<infer R>;
}
  ? R
  : never;
