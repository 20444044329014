import { createContext, useContext, type ReactNode } from "react";

export type CssOptionsValue = {
  /**
   * Nonce will be added to dynamically created `<style>` element
   * to allow stricter CSP.
   *
   * A nonce should be obtained dynamically, e.g. from a `<meta>` element.
   *
   * The `nonce` is applied during `style` element creation,
   * which only happens once. After that, updates to `nonce` option
   * are ignored.
   *
   * ```tsx
   * const nonce = document
   *   .querySelector("meta[property=csp-nonce]")
   *   .nonce
   *
   * <CssOptions nonce={nonce}>
   *   {children}
   * </CssOptions>
   * ```
   *
   * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce)
   * [Vite Docs](https://vitejs.dev/guide/features.html#nonce-random)
   */
  nonce?: string;

  /**
   * If enabled, all unknown or unsupported CSS properties will be injected.
   *
   * ⚠️ Caution!
   *
   * This library implements Atomic CSS approach.
   *
   * It has many benefits; but the limitation is that every individual
   * CSS property is injected as a separate atomic CSS rule
   * in a non-deterministic order.
   *
   * It's fine as long as CSS properties do not override each other.
   * But if you try to combine e.g. `margin` and `marginTop` the end result
   * will depend on rule order, which may change depending on how the user
   * navigates through the app.
   *
   * @defaultValue true
   */
  dangerouslyAllowUnknownProperties?: boolean;
};

const CssOptionsContext = createContext<CssOptionsValue>({
  dangerouslyAllowUnknownProperties: true,
});

export const useCssOptions = () => {
  return useContext(CssOptionsContext);
};

export const CssOptions = ({
  value,
  children,
}: {
  value: CssOptionsValue;
  children: ReactNode;
}) => {
  return (
    <CssOptionsContext.Provider value={value}>
      {children}
    </CssOptionsContext.Provider>
  );
};
