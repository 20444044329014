/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  type MutableRefObject,
  type ReactNode,
} from "react";
import { useHover } from "react-aria";
import useResizeObserver from "use-resize-observer";

import { icons as fleetIcons } from "@fleet/ui";
import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { Button, useIsMobile } from "@superweb/ui";

import { useMessage } from "#intl";

type RowWithScrollProps = {
  children: ReactNode;
  disabledShadow?: boolean;
  disabledAlign?: boolean;
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
  alwaysShowArrows?: boolean;
};

const useButtonMessages = () => {
  const message = useMessage();

  return {
    previousButtonLabel: message({
      id: "d8bf0553-09b8-4b50-8862-6cef99f7b7d8",
      context: "Row with scroll. Arrow button",
      default: "Scroll to previous",
    }),
    nextButtonLabel: message({
      id: "a62e35e8-02df-4eaa-9e3f-7f09b36f5f43",
      context: "Row with scroll. Arrow button",
      default: "Scroll to next",
    }),
  };
};

/**
 * @deprecated - use RowWithScrollV2 instead
 */
const DeprecatedRowWithScroll = ({
  children,
  disabledShadow = false,
  disabledAlign = false,
  scrollRef,
  alwaysShowArrows,
}: RowWithScrollProps) => {
  const isMobile = useIsMobile();

  const innerRef = useRef<HTMLDivElement>(null);
  const ref = scrollRef ?? innerRef;
  const {
    textInfo: { direction },
  } = useLocale();

  const { hoverProps, isHovered } = useHover({});

  const shouldShowArrows = alwaysShowArrows || isMobile || isHovered;

  const { width: containerWidth = 0 } = useResizeObserver({ ref });

  const [scrollStartPosition, setScrollStartPosition] = useState(0);
  const [scrollStartMax, setScrollStartMax] = useState(0);

  const scrollEnd = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({
      left: direction === "ltr" ? containerWidth : -containerWidth,
      behavior: "smooth",
    });
  }, [containerWidth, direction, ref]);

  const scrollStart = useCallback(() => {
    if (!ref.current) return;
    ref.current.scrollBy({
      left: direction === "ltr" ? -containerWidth : containerWidth,
      behavior: "smooth",
    });
  }, [containerWidth, direction, ref]);

  useLayoutEffect(() => {
    if (!ref.current?.scrollWidth) return;
    const scrollContainer = ref.current;
    setScrollStartMax(scrollContainer.scrollWidth - containerWidth);
    const onScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      setScrollStartPosition(target.scrollLeft);
    };
    scrollContainer.addEventListener("scroll", onScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [ref, containerWidth]);

  const hasScrollStart = Math.abs(scrollStartPosition) > 8;
  const hasScrollEnd = scrollStartMax - Math.abs(scrollStartPosition) > 8;

  const { nextButtonLabel, previousButtonLabel } = useButtonMessages();

  return (
    <div
      {...hoverProps}
      css={{
        position: "relative",
        display: "grid",
        alignContent: disabledAlign ? undefined : "center",
      }}
    >
      <div
        ref={ref}
        css={{
          display: "flex",
          overflowX: "auto",
          scrollSnapType: "x proximity",
          scrollbarWidth: "none",
          ...(!disabledShadow && {
            maskImage: `linear-gradient(
              ${direction === "ltr" ? "to right" : "to left"}, 
              ${hasScrollStart ? "transparent, transparent 25px, black 50px" : "black"}, 
              black calc(100% - 50px), 
              ${hasScrollEnd ? "transparent calc(100% - 25px), transparent" : "black"}
            )`,
          }),
        }}
        __experimental_webkitScrollbarCss={{ display: "none" }}
      >
        {children}
      </div>
      {shouldShowArrows && hasScrollStart && (
        <div
          css={{
            position: "absolute",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={
              direction === "rtl" ? nextButtonLabel : previousButtonLabel
            }
            icon={fleetIcons.ArrowInlineStart}
            onPress={() => {
              scrollStart();
            }}
          />
        </div>
      )}
      {shouldShowArrows && hasScrollEnd && (
        <div
          css={{
            position: "absolute",
            insetInlineEnd: "0px",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={
              direction === "rtl" ? previousButtonLabel : nextButtonLabel
            }
            icon={fleetIcons.ArrowInlineEnd}
            onPress={() => {
              scrollEnd();
            }}
          />
        </div>
      )}
    </div>
  );
};

const RowWithScrollV2 = ({
  children,
  disabledShadow = false,
  disabledAlign = false,
  scrollRef,
  alwaysShowArrows,
}: RowWithScrollProps) => {
  const isMobile = useIsMobile();

  const innerRef = useRef<HTMLDivElement>(null);
  const ref = scrollRef ?? innerRef;
  const {
    textInfo: { direction },
  } = useLocale();

  const { hoverProps, isHovered } = useHover({});

  const shouldShowArrows = alwaysShowArrows || isMobile || isHovered;

  const [canScrollStart, setCanScrollStart] = useState(false);
  const [canScrollEnd, setCanScrollEnd] = useState(true);

  const checkScrollPosition = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    setCanScrollStart(scrollContainer.scrollLeft > 0);
    setCanScrollEnd(
      scrollContainer.scrollLeft + scrollContainer.clientWidth <
        scrollContainer.scrollWidth,
    );
  }, [ref]);

  const scrollEnd = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    ref.current.scrollBy({
      left:
        direction === "ltr"
          ? scrollContainer.clientWidth
          : -scrollContainer.clientWidth,
      behavior: "smooth",
    });
  }, [direction, ref]);

  const scrollStart = useCallback(() => {
    if (!ref.current) return;
    const scrollContainer = ref.current;
    ref.current.scrollBy({
      left:
        direction === "ltr"
          ? -scrollContainer.clientWidth
          : scrollContainer.clientWidth,
      behavior: "smooth",
    });
  }, [direction, ref]);

  useLayoutEffect(() => {
    checkScrollPosition();
    if (!ref.current) return;
    const scrollContainer = ref.current;
    scrollContainer.addEventListener("scroll", checkScrollPosition);
    return () =>
      scrollContainer.removeEventListener("scroll", checkScrollPosition);
  }, [checkScrollPosition, ref]);

  useResizeObserver({
    ref,
    onResize: checkScrollPosition,
  });

  const { nextButtonLabel, previousButtonLabel } = useButtonMessages();

  return (
    <div
      {...hoverProps}
      css={{
        position: "relative",
        display: "grid",
        alignContent: disabledAlign ? undefined : "center",
      }}
    >
      <div
        ref={ref}
        css={{
          display: "flex",
          overflowX: "auto",
          ...cssFns.overscrollBehavior("contain"),
          scrollSnapType: "x proximity",
          scrollbarWidth: "none",
          ...(!disabledShadow && {
            maskImage: `linear-gradient(
              ${direction === "ltr" ? "to right" : "to left"}, 
              ${canScrollStart ? "transparent, transparent 25px, black 50px" : "black"}, 
              black calc(100% - 50px), 
              ${canScrollEnd ? "transparent calc(100% - 25px), transparent" : "black"}
            )`,
          }),
        }}
        __experimental_webkitScrollbarCss={{ display: "none" }}
      >
        {children}
      </div>
      {shouldShowArrows && canScrollStart && (
        <div
          css={{
            position: "absolute",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={
              direction === "rtl" ? nextButtonLabel : previousButtonLabel
            }
            icon={fleetIcons.ArrowInlineStart}
            onPress={() => {
              scrollStart();
            }}
          />
        </div>
      )}
      {shouldShowArrows && canScrollEnd && (
        <div
          css={{
            position: "absolute",
            insetInlineEnd: "0px",
            insetBlockStart: "0",
            insetBlockEnd: "0",
            display: "grid",
            alignContent: "center",
          }}
        >
          <Button
            view="floating"
            type="button"
            shape="circle"
            size="xs"
            ariaLabel={
              direction === "rtl" ? previousButtonLabel : nextButtonLabel
            }
            icon={fleetIcons.ArrowInlineEnd}
            onPress={() => {
              scrollEnd();
            }}
          />
        </div>
      )}
    </div>
  );
};

export const RowWithScroll = ({
  showV2Component,
  ...props
}: RowWithScrollProps & { showV2Component?: boolean }) =>
  showV2Component ? (
    <RowWithScrollV2 {...props} />
  ) : (
    <DeprecatedRowWithScroll {...props} />
  );
