/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { Button, icons, useUiColors } from "@superweb/ui";
import {
  useCallback,
  useState,
  useRef,
  useEffect,
  type ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import { useLocale } from "@superweb/intl";

import { useMessage } from "#intl";

type Story = {
  duration: number;
  content: ReactNode;
};

const Context = createContext<{
  stop: () => void;
  play: () => void;
  next: () => void;
  prev: () => void;
  currentId: number;
  isPaused: boolean;
  stories: Story[];
}>({
  stop: () => {},
  play: () => {},
  next: () => {},
  prev: () => {},
  currentId: 0,
  isPaused: false,
  stories: [],
});

const StoryProgressBar = ({ progress }: { progress: number }) => {
  const uiColors = useUiColors();
  const { textInfo } = useLocale();
  return (
    <div
      css={{
        backgroundColor: cssFns.setOpacity(uiColors.textInvert, 0.1),
        overflowY: "hidden",
        height: "4px",
        ...cssFns.border({ radius: "22px" }),
      }}
    >
      <div
        css={{
          backgroundColor: uiColors.textInvert,
          maxWidth: "100%",
          height: "100%",
          transformOrigin: `center ${
            textInfo.direction === "rtl" ? "right" : "left"
          }`,
        }}
        style={{ transform: `scaleX(${progress / 100})` }}
      />
    </div>
  );
};

const StoryProgressBars = ({
  onStoryStart,
  onStoryEnd,
}: {
  onStoryStart?: (index: number) => void;
  onStoryEnd?: (index: number) => void;
}) => {
  const { next, currentId, stories, isPaused } = useContext(Context);
  const [progress, setProgress] = useState(0);
  const lastTime = useRef<number>();
  const animationFrameId = useRef<number>();

  const duration = stories[currentId]?.duration;

  const increment = useCallback(
    (time: number) => {
      if (!duration) return;

      if (lastTime.current === undefined) {
        lastTime.current = time;
      }
      const diff = time - lastTime.current;
      lastTime.current = time;

      let nextProgress = 0;
      setProgress((progress) => {
        nextProgress = progress + (diff * 100) / duration;
        return nextProgress > 100 ? 100 : nextProgress;
      });

      if (nextProgress < 100) {
        animationFrameId.current = requestAnimationFrame(increment);
      } else {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
        }
        onStoryEnd?.(currentId);
        next();
      }
    },
    [duration, next, onStoryEnd, currentId],
  );

  useEffect(() => {
    if (!isPaused) {
      animationFrameId.current = requestAnimationFrame(increment);
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      lastTime.current = undefined;
    };
  }, [currentId, isPaused, increment]);

  useLayoutEffect(() => {
    setProgress(0);
    onStoryStart?.(currentId);
  }, [currentId, increment, onStoryStart]);

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: `repeat(${stories.length}, 1fr)`,
        columnGap: "8px",
        boxSizing: "border-box",
      }}
    >
      {stories.map((_, index) => (
        <StoryProgressBar
          key={index}
          progress={
            currentId === index ? progress : currentId > index ? 100 : 0
          }
        />
      ))}
    </div>
  );
};

const IconCross = (props: { className?: string }) => {
  const uiColors = useUiColors();
  return <icons.Cross color={uiColors.textInvert} {...props} />;
};

export const useStories = () => {
  return useContext(Context);
};

export const Stories = ({
  stories,
  loop,
  narrow,
  paused = false,
  onStoryStart,
  onStoryEnd,
  onClose,
}: {
  stories: Story[];
  loop?: boolean;
  narrow?: boolean;
  paused?: boolean;
  onStoryStart?: (index: number) => void;
  onStoryEnd?: (index: number) => void;
  onClose?: (index: number) => void;
}) => {
  const [currentId, setCurrentId] = useState(0);
  const [isPaused, setIsPaused] = useState(paused);
  const message = useMessage();

  const next = useCallback(() => {
    setCurrentId((currentId) =>
      currentId >= stories.length - 1 ? (loop ? 0 : currentId) : currentId + 1,
    );
  }, [stories, loop]);

  const prev = useCallback(() => {
    setCurrentId((currentId) => (currentId === 0 ? currentId : currentId - 1));
  }, []);

  const stop = useCallback(() => setIsPaused(true), []);

  const play = useCallback(() => setIsPaused(false), []);

  const getOrder = (index: number, pos: number, itemsCount: number) => {
    return index - pos < 0 ? itemsCount + pos - index : index - pos;
  };

  return (
    <Context.Provider
      value={{
        next,
        prev,
        play,
        stop,
        currentId,
        isPaused,
        stories,
      }}
    >
      <div css={{ position: "relative" }}>
        <div
          css={{
            display: "grid",
            alignItems: "start",
          }}
        >
          <ul
            css={{
              width: "100%",
              height: "100%",
              listStyleType: "none",
              position: "relative",
              display: "flex",
              ...cssFns.margin("0px"),
              ...cssFns.padding("0px"),
              overflowX: "hidden",
              outlineStyle: "none",
            }}
          >
            {stories.map((item, index) => (
              <li
                key={index}
                css={{
                  order: getOrder(index, currentId, stories.length).toString(),
                  width: "100%",
                  visibility: currentId !== index ? "hidden" : "visible",
                  ...cssFns.flex({ grow: "0", shrink: "0", basis: "100%" }),
                }}
                aria-hidden={index !== currentId}
              >
                {item.content}
              </li>
            ))}
          </ul>
        </div>
        <div
          css={{
            display: "grid",
            position: "absolute",
            gridTemplateColumns: narrow ? "1fr auto" : undefined,
            alignItems: "center",
            width: "100%",
            top: "0px",
            left: "0px",
            boxSizing: "border-box",
            ...cssFns.padding(narrow ? "16px" : "32px"),
          }}
        >
          <StoryProgressBars
            onStoryStart={onStoryStart}
            onStoryEnd={onStoryEnd}
          />
          {onClose && (
            <div
              css={{
                marginBlockStart: narrow ? undefined : "12px",
                marginInlineStart: "6px",
                marginInlineEnd: narrow ? "-10px" : "-12px",
                justifySelf: "flex-end",
              }}
            >
              <Button
                onPress={() => onClose(currentId)}
                ariaLabel={message({
                  id: "a9a9e9b1-9074-4cb9-8024-913ee9191947",
                  context: "Stories. Close button label",
                  default: "Close",
                })}
                icon={IconCross}
                view="ghost"
                size="s"
              />
            </div>
          )}
        </div>
      </div>
    </Context.Provider>
  );
};
