import type { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="30" viewBox="0 0 86 30" fill="none" {...props}>
    <path
      d="M9.65702 8.27989C9.58302 9.50089 9.47202 10.9809 9.39802 11.4619C9.58302 11.0179 10.138 9.87089 10.841 8.61289L14.578 1.73089H21.127L9.80502 19.0839L6.84502 28.0009H0.999023L4.10702 18.6399L4.18102 1.73089H9.95302L9.65702 8.27989Z"
      fill="#FF4930"
    />
    <path
      d="M22.7955 1.73089H30.6765L26.8655 28.0009H21.2415L22.0555 23.2649H17.3565L15.1365 28.0009H9.17949L22.7955 1.73089ZM19.7245 18.3069H22.9435L24.3865 10.0929C24.6085 8.87189 24.9045 7.42889 24.9785 6.94789C24.7935 7.39189 24.3125 8.57589 23.6835 9.87089L19.7245 18.3069Z"
      fill="#FF4930"
    />
    <path
      d="M41.7311 14.6439C41.6571 15.8649 41.5461 17.3449 41.5091 17.8259C41.6571 17.3819 41.9901 16.1609 42.4711 14.7919L46.7631 1.73089H52.1281L43.3961 28.0009H37.1801L37.9941 14.4219C38.0681 13.2009 38.2161 11.7209 38.2531 11.2399C38.1051 11.6839 37.7721 12.9049 37.2911 14.2739L32.7771 28.0009H27.4121L36.1441 1.73089H42.5081L41.7311 14.6439Z"
      fill="#FF4930"
    />
    <path
      d="M55.7793 18.0849L57.4073 13.1269H65.3993L60.4413 28.0009H56.2603L56.6303 25.8919C55.3353 27.4829 54.0773 28.1859 52.2643 28.1859C47.0473 28.1859 46.8253 21.1559 49.3043 13.5709C52.0053 5.31989 55.8533 1.21289 60.7003 1.21289C64.9183 1.21289 66.7313 4.28389 66.9533 8.09489L61.7363 9.83389C61.5513 6.83689 60.9963 5.98589 60.1453 5.98589C58.6283 5.98589 57.2963 7.87289 55.1133 14.5329C53.5593 19.2689 52.8563 23.6719 54.7063 23.6719C56.3343 23.6719 57.6663 20.8969 58.4803 18.4179L58.5913 18.0849H55.7793Z"
      fill="#FF4930"
    />
    <path
      d="M71.0218 28.4819C63.8808 28.4819 63.8438 21.1559 66.3228 13.5709C68.9868 5.31989 72.9828 1.24989 77.5338 1.24989C84.6748 1.24989 84.7118 8.57589 82.2328 16.1609C79.5688 24.4119 75.5728 28.4819 71.0218 28.4819ZM71.6138 23.7829C72.8718 23.7829 74.2408 21.9699 76.4238 15.1989C77.8668 10.7589 79.1248 5.94889 76.9418 5.94889C75.6838 5.94889 74.3148 7.76189 72.1318 14.5329C70.6888 18.8989 69.3938 23.7829 71.6138 23.7829Z"
      fill="#FF4930"
    />
  </svg>
);
