/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";
import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { useIsMobile, useUiColors } from "@superweb/ui";

import { Slide, type SlideProps } from "./slide";

type ActionUrl = {
  action_type: "url";
  url: string;
  is_url_external: boolean;
};

type ActionNext = {
  action_type: "next";
};

type ScenarioDialogParameters = {
  id: "scenario";
  scenario_id: string;
};

type ActionDialog = {
  action_type: "dialog";
  parameters: ScenarioDialogParameters;
};

export type ButtonAction = ActionNext | ActionUrl | ActionDialog;

type ActionButtons = {
  primaryButtonAction?: ButtonAction;
  secondaryButtonAction?: ButtonAction;
};

type Slides = Array<
  SlideProps &
    ActionButtons &
    Partial<{
      duration: number;
      progressColor: string;
      progressFillerColor: string;
    }>
>;

interface SlidesContextValue {
  currentSlideIndex: number;
  nextSlide: (trigger: "timer" | "click" | "keyboard") => void;
  previousSlide: (trigger: "click" | "keyboard") => void;
  holdSlide: (isHolding: boolean) => void;
  isPaused: boolean;
  slides: Slides;
  onActionSlide?: (action: "primary" | "secondary") => void;
  onContinueScenario?: (scenarioId: string) => void;
  onClose: (isTrigger?: boolean) => void;
}

const ProgressBar = ({
  progress,
  color,
  fillerColor,
}: {
  progress: number;
  color?: string;
  fillerColor?: string;
}) => {
  const uiColors = useUiColors();
  const { textInfo } = useLocale();
  return (
    <div
      css={{
        backgroundColor: cssFns.setOpacity(color ?? uiColors.textInvert, 0.3),
        height: "3px",
        ...cssFns.border({ radius: "22px" }),
        ...cssFns.overflow("hidden"),
      }}
    >
      <div
        css={{
          backgroundColor: fillerColor ?? uiColors.textInvert,
          maxWidth: "100%",
          height: "100%",
          transformOrigin: `center ${
            textInfo.direction === "rtl" ? "right" : "left"
          }`,
          ...cssFns.border({ radius: "22px" }),
        }}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

const ProgressBars = ({
  slides,
  currentSlideIndex,
  isPaused,
  onNextSlide,
  onSlideEnd,
}: {
  slides: Array<
    Partial<{
      duration: number;
      progressColor: string;
      progressFillerColor: string;
    }>
  >;
  currentSlideIndex: number;
  isPaused?: boolean;
  onNextSlide: () => void;
  onSlideEnd?: (index: number) => void;
}) => {
  const lastTime = useRef<number>();
  const animationFrameId = useRef<number>();
  const [progress, setProgress] = useState(0);

  const duration = slides[currentSlideIndex]?.duration;

  const increment = useCallback(
    (time: number) => {
      if (!duration) return;

      if (lastTime.current === undefined) {
        lastTime.current = time;
      }
      const diff = time - lastTime.current;
      lastTime.current = time;

      let nextProgress = 0;
      setProgress((progress) => {
        nextProgress = progress + (diff * 100) / duration;
        return nextProgress > 100 ? 100 : nextProgress;
      });

      if (nextProgress < 100) {
        animationFrameId.current = requestAnimationFrame(increment);
      } else {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
        }
        onSlideEnd?.(currentSlideIndex);
        onNextSlide();
      }
    },
    [duration, onNextSlide, onSlideEnd, currentSlideIndex],
  );

  useLayoutEffect(() => {
    if (!isPaused) {
      animationFrameId.current = requestAnimationFrame(increment);
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      lastTime.current = undefined;
    };
  }, [currentSlideIndex, isPaused, increment]);

  useLayoutEffect(() => {
    setProgress(0);
  }, [slides, currentSlideIndex]);

  return (
    <div
      css={{
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
        columnGap: "2px",
        boxSizing: "border-box",
      }}
    >
      {slides.map(({ duration }, index) => (
        <ProgressBar
          key={index}
          progress={
            currentSlideIndex === index
              ? duration === 0
                ? 100
                : progress
              : currentSlideIndex > index
                ? 100
                : 0
          }
          color={slides[currentSlideIndex]?.progressColor}
          fillerColor={slides[currentSlideIndex]?.progressFillerColor}
        />
      ))}
    </div>
  );
};

export const Slides = () => {
  const isMobile = useIsMobile();
  const {
    nextSlide,
    previousSlide,
    currentSlideIndex,
    isPaused,
    holdSlide,
    slides,
    onActionSlide,
  } = useSlides();

  const slide = useMemo(
    () => slides[currentSlideIndex],
    [currentSlideIndex, slides],
  );

  const handleNextSlide = useCallback(() => nextSlide("timer"), [nextSlide]);
  const fullHeight = CSS.supports("height: 100dvh") ? "100dvh" : "100vh";

  if (!slide) return null;

  return (
    <div
      css={{
        width: isMobile ? "100vw" : "368px",
        height: isMobile ? fullHeight : "650px",
        position: "relative",
      }}
    >
      <div
        css={{
          position: "absolute",
          alignItems: "center",
          width: "100%",
          top: "0px",
          left: "0px",
          boxSizing: "border-box",
          ...cssFns.padding("16px"),
          paddingBlockStart: "32px",
        }}
      >
        <ProgressBars
          slides={slides}
          currentSlideIndex={currentSlideIndex}
          isPaused={isPaused}
          onNextSlide={handleNextSlide}
        />
      </div>
      <div css={{ display: "grid", height: "100%" }}>
        {slides.map((slide, index) => (
          <div
            key={index}
            css={{
              display: index !== currentSlideIndex ? "none" : undefined,
              maxHeight: isMobile ? fullHeight : "650px",
            }}
          >
            <Slide
              {...slide}
              onSlideHold={holdSlide}
              onSlidePassed={() => nextSlide("click")}
              onSlidePrevious={() => previousSlide("click")}
              onPressPrimary={() => onActionSlide?.("primary")}
              onPressSecondary={() => onActionSlide?.("secondary")}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const SlidesContext = createContext<SlidesContextValue>({
  currentSlideIndex: 0,
  nextSlide: () => {},
  previousSlide: () => {},
  isPaused: false,
  holdSlide: () => {},
  slides: [],
  onClose: () => {},
});

export const useSlides = () => useContext(SlidesContext);

export const SlidesProvider = ({
  slides,
  currentStoryId,
  currentStoryIndex,
  children,
  onSlideNext,
  onSlidePrevious,
  onActionSlide,
  onSlideStart,
  onContinueScenario,
  onClose,
}: {
  slides: Slides;
  currentStoryId: string;
  currentStoryIndex: number;
  children?: ReactNode;
  onSlideNext?: (
    storyId: string,
    indexSlide: number,
    trigger: "timer" | "click" | "keyboard",
  ) => void;
  onSlidePrevious?: (
    storyId: string,
    indexSlide: number,
    trigger: "click" | "keyboard",
  ) => void;
  onActionSlide?: ({
    isExternal,
    url,
    idStory,
    indexSlide,
    typeAction,
  }: {
    isExternal?: boolean;
    url?: string;
    idStory: string;
    indexSlide: number;
    typeAction: "primary" | "secondary";
  }) => void;
  onSlideStart?: (currentIndex: number, currentStoryIndex: number) => void;
  onContinueScenario?: (
    scenarioId: string,
    subStepId?: string,
    options?: {
      startFromActionId?: string;
      allowDialogOpening?: boolean;
    },
  ) => void;
  onClose: (isTrigger?: boolean) => void;
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(!slides[0]?.duration);

  const slide = useMemo(
    () => slides[currentSlideIndex],
    [slides, currentSlideIndex],
  );

  const nextSlide = useCallback(
    (trigger: "timer" | "click" | "keyboard") => {
      const nextIndex =
        currentSlideIndex === slides.length - 1 ? 0 : currentSlideIndex + 1;
      setCurrentSlideIndex(nextIndex);
      onSlideNext?.(currentStoryId, currentSlideIndex, trigger);
      if (nextIndex !== 0) {
        onSlideStart?.(nextIndex, currentStoryIndex);
      }
    },
    [
      currentSlideIndex,
      onSlideNext,
      currentStoryId,
      slides,
      onSlideStart,
      currentStoryIndex,
    ],
  );

  const previousSlide = (trigger: "click" | "keyboard") => {
    const previousIndex = Math.max(currentSlideIndex - 1, 0);
    setCurrentSlideIndex((currentIndex) => {
      if (currentIndex !== previousIndex) {
        onSlideStart?.(previousIndex, currentStoryIndex);
      }
      return previousIndex;
    });
    onSlidePrevious?.(currentStoryId, currentSlideIndex, trigger);
  };

  const holdSlide = (isHolding: boolean) => {
    if (!slide?.duration) return;
    setIsPaused(isHolding);
  };

  const handleActionSlide = (action: "primary" | "secondary") => {
    const buttonAction = {
      primary: slide?.primaryButtonAction,
      secondary: slide?.secondaryButtonAction,
    }[action];
    const metricsData = {
      idStory: currentStoryId,
      indexSlide: currentSlideIndex,
      typeAction: action,
    };

    if (buttonAction?.action_type === "url") {
      if (!buttonAction.is_url_external && buttonAction.url) {
        onClose();
      }
      onActionSlide?.({
        isExternal: buttonAction.is_url_external,
        url: buttonAction.url,
        ...metricsData,
      });
    } else if (buttonAction?.action_type === "dialog") {
      onContinueScenario?.(buttonAction.parameters.scenario_id, undefined, {
        allowDialogOpening: true,
      });
      onClose();
      onActionSlide?.(metricsData);
    } else {
      nextSlide("click");
      onActionSlide?.(metricsData);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!slide?.duration) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  }, [slide]);

  useEffect(() => {
    onSlideStart?.(0, currentStoryIndex);
    setCurrentSlideIndex(0);
  }, [onSlideStart, currentStoryId, currentStoryIndex]);

  return (
    <SlidesContext.Provider
      value={{
        nextSlide,
        previousSlide,
        currentSlideIndex,
        isPaused,
        holdSlide,
        slides,
        onActionSlide: handleActionSlide,
        onClose,
      }}
    >
      {children}
    </SlidesContext.Provider>
  );
};
