/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect, useRef, type ReactNode, type RefObject } from "react";
import {
  DismissButton,
  Overlay,
  useListBox,
  useOption,
  useOverlayPosition,
  usePopover,
  type AriaListBoxOptions,
  type AriaPopoverProps,
} from "react-aria";
import type { ListState, OverlayTriggerState } from "react-stately";
import { mergeProps, useResizeObserver } from "@react-aria/utils";

import { cssFns, useCss, type Style } from "@superweb/css";

import { useUiColors } from "./theme";
import { icons } from "./icons";
import { useTypo } from "./typo";
import { usePopoverContainer } from "./popover";
import { useLayoutOverlayPosition } from "./layouts/layout-container";

const checkIconSizeMap: Record<Size, Style> = {
  xs: { width: "16px", height: "16px" },
  s: { width: "18px", height: "18px" },
  m: { width: "20px", height: "20px" },
  l: { width: "24px", height: "24px" },
};

const iconSizeMap: Record<Size, Style> = {
  xs: { width: "16px", height: "16px" },
  s: { width: "16px", height: "16px" },
  m: { width: "32px", height: "32px" },
  l: { width: "40px", height: "40px" },
};

const itemSizeMap: Record<Size, string> = {
  xs: "32px",
  s: "40px",
  m: "48px",
  l: "56px",
};

type Size = "l" | "m" | "s" | "xs";
/**
 * Component for display a list of options in popover.
 * @internal
 * Used in the select and combobox components.
 */
export const ListBox = <T extends {}>({
  optionSize = "l",
  ariaListBoxProps,
  listBoxRef,
  state,
}: {
  optionSize?: Size;
  ariaListBoxProps: AriaListBoxOptions<T>;
  listBoxRef: RefObject<HTMLUListElement>;
  state: ListState<T>;
}) => {
  const { listBoxProps } = useListBox(ariaListBoxProps, state, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      // Issues https://github.com/adobe/react-spectrum/issues/4793
      // https://github.com/adobe/react-spectrum/pull/4285/files
      onKeyDown={(e) => {
        if (!listBoxProps.onKeyDown) {
          return;
        }

        if (
          e.key === "Escape" &&
          // To prevent removing selection after escape button press we add this check
          // More details:
          // https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/selection/src/useSelectableCollection.ts#L269
          ariaListBoxProps.disallowEmptySelection !== true
        ) {
          return;
        }

        listBoxProps.onKeyDown(e);
      }}
      ref={listBoxRef}
      css={{
        ...cssFns.padding("0"),
        ...cssFns.border({ width: "0" }),
        ...cssFns.margin("0"),
        listStyleType: "none",
        outlineStyle: "none",
        overscrollBehaviorBlock: "contain",
      }}
    >
      {[...state.collection].map(({ key, rendered }) => (
        <Option
          key={key}
          item={{
            key,
            rendered,
          }}
          state={state}
          size={optionSize}
        />
      ))}
    </ul>
  );
};

export const ListBoxPopover = ({
  popoverRef,
  triggerRef,
  state,
  children,
  ...restProps
}: {
  popoverRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLElement>;
  state: OverlayTriggerState;
  children: ReactNode;
} & AriaPopoverProps) => {
  const uiColors = useUiColors();

  const popoverContainer = usePopoverContainer();

  const { popoverProps } = usePopover(
    {
      ...restProps,
      popoverRef,
      triggerRef,
      offset: 8,
    },
    state,
  );

  /**
   * workaround for the issue with the popover position when the trigger change size.
   * usePopover doesn't update the position of the popover when the trigger changes size.
   * using useOverlayPosition to update the position of the popover when the trigger changes size.
   * https://github.com/adobe/react-spectrum/issues/4040
   */
  const { overlayProps, updatePosition } = useOverlayPosition({
    ...restProps,
    overlayRef: popoverRef,
    targetRef: triggerRef,
    offset: 8,
  });

  useResizeObserver({ ref: triggerRef, onResize: updatePosition });

  const layoutOverlayProps = useLayoutOverlayPosition({
    ...restProps,
    overlayRef: popoverRef,
    targetRef: triggerRef,
    offset: 8,
  });

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  useEffect(() => {
    const element = popoverRef.current;
    const handleTouchStart = (e: Event) => e.preventDefault();
    element?.addEventListener("touchstart", handleTouchStart);
    return () => element?.removeEventListener("touchstart", handleTouchStart);
  }, [popoverRef]);

  return (
    <Overlay portalContainer={popoverContainer}>
      <div>
        <div
          {...mergeProps(popoverProps, overlayProps, layoutOverlayProps)}
          ref={popoverRef}
          css={{
            minHeight: "56px",
            width: `${triggerRef.current?.offsetWidth}px`,
            ...cssFns.border({ radius: "16px" }),
            overflowY: "auto",
            backgroundColor: uiColors.background,
            ...cssFns.boxShadow({
              offsetY: "8px",
              blurRadius: "20px",
              color: uiColors.fog,
            }),
          }}
        >
          {children}
        </div>
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

const Option = <T extends {}>({
  size,
  item,
  state,
}: {
  size: Size;
  item: {
    key: string | number;
    rendered: ReactNode;
  };
  state: ListState<T>;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const ref = useRef<HTMLLIElement>(null);

  const isSelected = state.selectionManager.isSelected(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  //  Whether option needs to reserve space for icon or not.
  //  It is used in listboxes with enabled selection to prevent long text from jumping when item was checked.
  const reserveIconSpace = state.selectionManager.selectionMode !== "none";

  const { optionProps } = useOption({ key: item.key, isSelected }, state, ref);

  const iconClassName = useCss({ ...checkIconSizeMap[size] });

  return (
    <li
      ref={ref}
      css={{
        minHeight: itemSizeMap[size],
        ...(["xs", "s"].includes(size)
          ? {
              ...cssFns.padding("4px", "12px"),
              ...typo({
                level: "caption1",
                density: "tight",
                weight: "medium",
              }),
            }
          : {
              ...cssFns.padding("8px"),
              paddingInlineEnd: "16px",
              ...typo({
                level: "body2",
                density: "tight",
                weight: "regular",
              }),
            }),

        boxSizing: "border-box",
        display: "grid",
        alignItems: "center",
        columnGap: "4px",
        gridTemplateColumns: "1fr auto",
        color: uiColors.text,
        overflowX: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: isFocused ? uiColors.hover : undefined,
        outlineStyle: "none",
        cursor: "pointer",
      }}
      {...optionProps}
    >
      {item.rendered}
      {(isSelected || reserveIconSpace) && (
        <div
          css={{
            ...iconSizeMap[size],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isSelected && (
            <icons.Check aria-hidden={true} className={iconClassName} />
          )}
        </div>
      )}
    </li>
  );
};

export const OptionContent = ({
  size = "m",
  label,
  description,
  icon,
  disabled = false,
}: {
  size?: Size;
  label: string;
  description?: string;
  icon?: ReactNode;
  disabled?: boolean;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "grid",
        rowGap: "1px",
        alignItems: "center",
        gridTemplateColumns: icon ? "min-content auto" : "auto",
        ...(disabled
          ? {
              opacity: "0.5",
              cursor: "none",
            }
          : {}),
        color: disabled ? cssFns.setOpacity(uiColors.text, 0.5) : uiColors.text,
        cursor: disabled ? "default" : "pointer",
      }}
    >
      {icon && (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "start",
            ...iconSizeMap[size],
          }}
        >
          {icon}
        </div>
      )}
      <div
        css={{
          display: "grid",
          marginInlineStart: ["xs", "s"].includes(size) ? "4px" : "8px",
        }}
      >
        <span
          css={{
            ...typo({
              level: size === "xs" ? "caption1" : "body2",
              density: "tight",
              weight: size === "xs" ? "medium" : "regular",
            }),
            ...cssFns.overflow("hidden"),
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </span>
        {description && !["s", "xs"].includes(size) && (
          <span
            css={{
              color: uiColors.textMinor,
              ...typo({
                level: "caption1",
                density: "tight",
                weight: "regular",
              }),
              ...cssFns.overflow("hidden"),
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </span>
        )}
      </div>
    </div>
  );
};
