declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
  }
}

export const initFBQ = (pixel_id?: string) => {
  if (window.fbq !== undefined) {
    window.fbq("init", pixel_id);
    window.fbq("track", "PageView");
  }
};

export const sendFBQEvent = (eventName: string, eventCategory: string) => {
  window.fbq?.("trackCustom", eventName, { event_category: eventCategory });
};

export const sendFBQLeadEvent = () => {
  window.fbq?.("track", "Lead");
};
